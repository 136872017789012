.react-datepicker {
    font-size: 1.5rem
}

.react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
}

.react-datepicker__day-name {
    width: 3rem;
}

.react-datepicker__current-month {
    font-size: 1.8rem;
    padding-bottom: 1rem;
}